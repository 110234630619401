export const VIEW_OPTIONS = [
    { name: '50', value: 50 },
    { name: '100', value: 100 },
    { name: '150', value: 150 },
    { name: '200', value: 200 },
]

export const ID_LIST_USING_WILDCARD = ['LIST12', 'LIST83', 'LIST13' ];

export enum EDomainFilter {
    Domain = 'domain',
    List = 'list',
    Category = 'category',
    SubmittedBy = 'submittedby',
    BatchNo = 'batchno',
    DateAdded = 'submitteddate'
}

export const DOMAIN_FILTER_OPTIONS = [
    { name: 'Domain', value: EDomainFilter.Domain },
    { name: 'List', value: EDomainFilter.List },
    { name: 'Category', value: EDomainFilter.Category },
    { name: 'Submitted By', value: EDomainFilter.SubmittedBy },
    { name: 'Batch No.', value: EDomainFilter.BatchNo },
    { name: 'Date Added', value: EDomainFilter.DateAdded },
]

export const DOMAIN_SORT_OPTIONS = [
    { name: 'Latest To Oldest', value: 'l' },
    { name: 'Oldest To Latest', value: 'o' },
    { name: 'S/N: Smallest To Biggest', value: 's' },
    { name: 'S/N: Biggest To Smallest', value: 'b' }
];
